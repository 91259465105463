<template>
  <WidgetContainer
    width="4"
    header="Top Performers"
    show-navigation-bar
    navigation-label="Go to Reporting"
    :navigation-action="
      () =>
        $router.push({
          name: 'ReportingOverview',
          query: {
            initial_dimensions: currentDimension,
            start_date: this.startDate,
            end_date: this.endDate
          }
        })
    "
  >
    <template v-slot:header>
      <DateRangeSelector
        presets-only
        @update="dates => updateDateRange(dates)"
      />
    </template>
    <template v-slot>
      <q-select
        filled
        dense
        v-model="currentDimension"
        :options="dimensionOptions"
        emit-value
        map-options
        :disable="loading"
        class="q-mx-md q-mb-sm"
        @update:model-value="populateWidget"
      />

      <DataTableSimple
        :key="'AdvertiserTopPerformersWidget_DataTable_' + currentDimension"
        table-key="AdvertiserTopPerformersWidget_DataTable"
        row-key="id"
        :columns="dataTableColumns"
        :rows="dataTableRows"
        :loading="loading"
        :pagination="{
          sortBy: 'clicks',
          descending: true,
          page: 1,
          rowsPerPage: 5
        }"
      />
    </template>
  </WidgetContainer>
</template>

<script>
import DataTableSimple from "@/components/UI/DataTableSimple";
import WidgetContainer from "@/components/UI/Widgets/WidgetContainer";
import DateRangeSelector from "@/components/UI/DateRangeSelector";

export default {
  name: "AdvertiserTopPerformersWidget",
  components: { DateRangeSelector, WidgetContainer, DataTableSimple },
  props: {},
  data() {
    return {
      loading: false,
      startDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD"),
      endDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD"),
      currentDimension: this.$store.state.productTypeConfigs.type,
      dimensionOptions: [
        {
          label: this.$store.state.productTypeConfigs.typeFormatted + "s",
          value: this.$store.state.productTypeConfigs.type
        },
        {
          label: "Advertiser Accounts",
          value: "advertiser_account"
        },
        {
          label: "Inventory Sources",
          value: "inventory_source"
        }
      ],
      dataTableRows: [],
      dataTableColumns: [
        {
          name: "product",
          label: this.$store.state.productTypeConfigs.typeFormatted,
          field: row =>
            row[this.$store.state.productTypeConfigs.nameField] +
            "<p class='--subtext'>" +
            row[this.$store.state.productTypeConfigs.identifierField] +
            "</p>"
        },
        {
          name: "impressions",
          label: "Impressions",
          field: "impressions",
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "clicks",
          label: "Clicks",
          field: "clicks",
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "ctr",
          label: "CTR",
          field: "ctr",
          sort: (a, b) => this.sortPercentage(a, b)
        }
      ]
    };
  },
  watch: {
    currentDimension(newVal) {
      let columnDataByDimension = {
        [this.$store.state.productTypeConfigs.type]: {
          name: "product",
          label: this.$store.state.productTypeConfigs.typeFormatted,
          nameField: this.$store.state.productTypeConfigs.nameField,
          idField: this.$store.state.productTypeConfigs.identifierField
        },
        advertiser_account: {
          name: "account",
          label: "Account",
          nameField: "advertiser_account_name",
          idField: "advertiser_account_id"
        },
        inventory_source: {
          name: "inventory_source",
          label: "Inventory Source",
          nameField: "inventory_source_name",
          idField: "inventory_source_id"
        }
      };

      this.dataTableColumns[0] = {
        name: columnDataByDimension[newVal].name,
        label: columnDataByDimension[newVal].label,
        field: row =>
          "<p class='ellipsis'>" +
          row[columnDataByDimension[newVal].nameField] +
          "</p>" +
          "<p class='--subtext'>" +
          row[columnDataByDimension[newVal].idField] +
          "</p>",
        style: "max-width: 10vw; min-width: 140px"
      };
    }
  },
  mounted() {
    this.populateWidget();
  },
  methods: {
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }

      // this.addUrlParamsToWindow([ { name: "start_date", value: this.startDate }, { name: "end_date", value: this.endDate }]);
      // this.saveStateToUrl("adrom", { startDate: this.startDate, endDate: this.endDate });

      this.populateWidget();
    },
    populateWidget() {
      this.loading = true;

      this.dataTableRows = [];

      this.$store
        .dispatch("advertiserReporting/POST_REQUEST", {
          params: {
            report_payload: {
              start_date: this.startDate,
              end_date: this.endDate,
              options: {
                include_time_last_updated: true,
                format_results: true,
                format_totals: true,
                enable_drilldown_mode: false,
                include_totals: true,
                disable_caching: true,
                dimension_key_indexed_results: false,
                sorting: [{ field: "clicks", direction: "desc" }],
                pagination: {
                  per_page: 10,
                  page: 1
                }
              },
              dimensions: {
                [this.currentDimension]: []
              },
              metrics: {
                impressions: [],
                clicks: [],
                ctr: []
              }
            }
          }
        })
        .then(report => {
          this.dataTableRows = "rows" in report ? report.rows : [];

          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
